<template>
  <div>
    <TitleCard title="分组详情" style="max-width: 97%; margin: 10px auto">
      <template v-slot:subTitle class="position-relative">
        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
          <li
              class="breadcrumb-item text-muted"
              rel="stylesheet"
              href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
              style="font-family: 'Roboto', sans-serif"
          >
            Group Detail
          </li>
          <div
              class="align-items-center py-3"
              style="position: absolute; right: 0; padding: 0 40px"
          >
            <el-button type="primary" size="small" style="margin-right: 5px" @click="openDeleteWindow(null)" :disabled="idArrs.length==0">批量删除商品</el-button>
            <el-button type="primary" size="small" style="margin-right: 10px" @click="batchPush" :disabled="idArrs.length==0">批量推送</el-button>
            <a href="javascript:void(0)" style="margin-right: 10px">
              <el-dropdown
                  size="small"
                  split-button
                  type="primary"
                  @command="configureGoods"
              >
                商品配置
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="2"
                  >配置自有商品
                  </el-dropdown-item
                  >
                  <el-dropdown-item command="1"
                  >{{userRole.includes('vipMember')?'配置商会商品池商品':'配置商品池商品'}}
                  </el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </a>
            <el-button type="primary" size="small" @click="$router.replace('/goods_group')">返回到分组管理</el-button>
          </div>
        </ul>
      </template>
    </TitleCard>
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <div
          id="kt_content_container"
          class="container"
          style="
          max-width: 98%;
          flex-direction: row !important;
          display: flex;
        "
      >
        <!-- 搜索开始 -->
        <div
            class="flex-column flex-lg-row-auto w-100 w-lg-250px w-xxl-325px mb-8 mb-lg-0 me-lg-9 me-5"
        >
          <form action="#" id="group_form1">
            <div class="card">
              <div class="card-body" style="padding: 1.2rem 1rem">
                <div class="mb-5" style="position: relative">
                  <div class="nav-group nav-group-fluid">
                    <label>
                      <input
                          type="radio"
                          class="btn-check"
                          name="goodsPlace"
                          @click="ownReviewedGoods(1)"
                          :checked="goodsPlace === 1"
                      />
                      <span
                          class="btn btn-sm btn-color-muted btn-active btn-active-primary er px-4 fw-bold"
                      >
                        自有商品
                      </span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="btn-check"
                          name="goodsPlace"
                          @click="ownReviewedGoods(2)"
                          :checked="goodsPlace === 2"
                      />
                      <span
                          class="btn btn-sm btn-color-muted btn-active btn-active-primary er px-4 fw-bold"
                      >
                        商品池商品
                      </span>
                    </label>
                  </div>
                  <div v-if="goodsPlace == 2 && !userRole.includes('vipMember')" class="arrow-down" style="position: absolute;bottom: -20px;right: 23%;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#009ef7"
                         class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                      <path
                          d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                    </svg>
                  </div>
                </div>
                <div class="mb-5  d-flex justify-content-center " v-if="goodsPlace == 2 && !userRole.includes('vipMember')">
                  <div class="nav-group">
                    <label>
                      <input
                          type="radio"
                          class="btn-check"
                          name="isJd"
                          @click="ownReviewedGoods(2,2)"
                          :checked="params.isJd === 2"
                      />
                      <span
                          style="width: 130px"
                          class="btn btn-sm btn-color-muted btn-active btn-active-primary er px-4 fw-bold"
                      >
                        供应商商品
                      </span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="btn-check"
                          name="isJd"
                          @click="ownReviewedGoods(2,1)"
                          :checked="params.isJd === 1"
                      />
                      <span
                          style="width: 130px"
                          class="btn btn-sm btn-color-muted btn-active btn-active-primary er px-4 fw-bold"
                      >
                        京东商品
                      </span>
                    </label>
                  </div>
                </div>
                <div class="separator separator-dashed my-8"></div>
                <div class="mb-5">
                  <label class="fs-6 form-label er text-dark fw-bold"
                  >商品标题</label
                  >
                  <div class="position-relative">
                    <span
                        class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6"
                    >
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                      >
                        <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <path
                              d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              opacity="0.3"
                          ></path>
                          <path
                              d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                          ></path>
                        </g>
                      </svg>
                    </span>
                    <input
                        id="title"
                        type="text"
                        class="form-control form-control-solid ps-10"
                        name="search"
                        v-model="params.title"
                        value=""
                        placeholder="请输入需要查询的商品标题名称"
                    />
                  </div>
                </div>
                <div class="mb-5">
                  <label class="fs-6 form-label er text-dark fw-bold"
                  >一级分类</label
                  >
                  <select
                      class="form-select form-select-solid"
                      v-model="params.categoryId1"
                      data-placeholder="全部"
                      @change="findCategory(1)"
                  >
                    <option value="">全部</option>
                    <option
                        v-for="(item, index) in categoryOne"
                        :key="index"
                        :value="item.categoryId"
                    >
                      {{ item.fullName }}
                    </option>
                  </select>
                </div>
                <div class="mb-5">
                  <label class="fs-6 form-label er text-dark fw-bold"
                  >二级分类</label
                  >
                  <select
                      class="form-select form-select-solid"
                      v-model="params.categoryId2"
                      @change="findCategory(2)"
                      data-placeholder="全部"
                  >
                    <option value="">全部</option>
                    <option
                        v-for="(item, index) in categoryTwo"
                        :key="index"
                        :value="item.categoryId"
                    >
                      {{ item.fullName }}
                    </option>
                  </select>
                </div>

                <div class="mb-5" v-if="params.isJd != 1">
                  <label class="fs-6 form-label fw-bolder text-dark">是否支持7天无理由</label>
                  <select class="form-select form-select-solid" v-model="params.sevenNRR" data-placeholder="全部">
                    <option value="">全部</option>
                    <option value="1">支持</option>
                    <option value="0">不支持</option>
                  </select>
                </div>

                <div class="mb-5" v-if="params.isJd != 1">
                  <label class="fs-6 form-label fw-bolder text-dark">发货时效</label>
                  <el-checkbox-group v-model="deliveryTime">
                    <el-checkbox label="24小时内发货"></el-checkbox>
                    <el-checkbox label="48小时内发货"></el-checkbox>
                    <el-checkbox label="48小时内发货(周末、节假日除外)"></el-checkbox>
                    <el-checkbox label="72小时内发货"></el-checkbox>
                    <el-checkbox label="其他"></el-checkbox>
                  </el-checkbox-group>
                </div>

                <div class="mb-5" v-if="this.goodsPlace == 1">
                  <label class="fs-6 form-label fw-bolder text-dark">商品来源</label>
                  <select class="form-select form-select-solid" v-model="params.goodsStaffId" data-placeholder="全部">
                    <option value="">全部</option>
                    <option v-for="(item, index) in staffArr" :key="index" :value="item.id">{{ item.companyName }}</option>
                  </select>
                </div>

                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark"
                  >利润区间</label
                  >
                  <div class="row">
                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                      <input
                          type="text"
                          id="minRightRate"
                          name="minRightRate"
                          class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                          v-model="params.minRightRate"
                          oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                          placeholder="最小值"
                          value=""
                      />
                      <div
                          class="fv-plugins-message-container invalid-feedback"
                      ></div>
                    </div>
                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                      <input
                          type="text"
                          id="maxRightRate"
                          name="maxRightRate"
                          class="form-control form-control-lg form-control-solid"
                          v-model="params.maxRightRate"
                          oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                          placeholder="最大值"
                          value=""
                      />
                      <div
                          class="fv-plugins-message-container invalid-feedback"
                      ></div>
                    </div>
                  </div>
                </div>

                <div class="d-flex align-items-center justify-content-end">
                  <a
                      @click="shaixuan"
                      href="javascript:void(0);"
                      class="btn btn-primary fw-bold"
                  >筛选</a
                  >&nbsp;&nbsp;&nbsp;
                  <a
                      @click="resetQuery"
                      href="javascript:void(0);"
                      class="btn btn-active-light-primary btn-color-gray-400 me-3 fw-bold"
                  >重置</a
                  >
                </div>
              </div>
            </div>
          </form>
        </div>

        <!-- 列表开始 -->
        <div
            class="card mb-5 mb-xl-8"
            style="margin-left: 3px; width: 100%"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            v-loading="loading"
        >
          <div class="card-body py-3" style="padding-left: 15px">
            <div class="table-responsive">
              <table
                  class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer"
                  id="kt_ecommerce_products_table"
              >
                <thead>
                <tr
                    class="text-start text-gray-500  fs-7 text-uppercase gs-0"
                >
                  <th
                      class="w-10px pe-2 sorting_disabled"
                      rowspan="1"
                      colspan="1"
                      aria-label=""
                      style="width: 2%"
                  >
                    <div
                        class="form-check form-check-sm form-check-custom form-check-solid me-3"
                    >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          data-kt-check="true"
                          data-kt-check-target="#kt_ecommerce_products_table .form-check-input"
                          value="TRUE"
                          v-model="checkboxInput"
                          @change="checkboxInputEvent"
                      />
                    </div>
                  </th>
                  <th
                      class="max-w-240px"
                      tabindex="0"
                      aria-controls="kt_ecommerce_products_table"
                      rowspan="1"
                      colspan="1"
                      aria-label="Product: activate to sort column ascending"
                      style="min-width: 17%"
                  >
                    商品信息
                  </th>
                  <th
                      class="text-center"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="分类"
                      :style="{ 'min-width': (goodsPlace == 2 || params.isJd == 1) ? '8%' : '12%' }"
                  >
                    分类
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="市场价"
                      style="width: 6%"
                  >
                    市场价
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="分销价"
                      style="width: 6%"
                  >
                    分销价
                  </th>
                  <th
                      v-if="!(goodsPlace == 2 && params.isJd == 1)"
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="全国商品池"
                      style="width: 5%"
                  >
                    商品池
                  </th>
                  <th
                      v-if="!(goodsPlace == 2 || params.isJd == 1)"
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="全国分销价(加价)"
                      style="width: 5%"
                  >
                    全国分销价
                  </th>
                  <th
                      v-if="(goodsPlace == 2 || params.isJd == 1)"
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="利润率"
                      style="width: 10%"
                  >
                    利润率
                  </th>
                  <th
                      v-if="!(goodsPlace == 2 || params.isJd == 1)"
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="利润率"
                      style="width: 6%"
                  >
                    利润率
                  </th>
                  <th v-if="!(goodsPlace == 2 || params.isJd == 1)"
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="销售价"
                      style="width: 5%"
                  >
                    销售价
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="销量"
                      style="width: 5%"
                  >
                    销量
                  </th>
                  <th
                      v-if="(goodsPlace == 2 || params.isJd == 1)"
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="运费模板"
                      style="width: 8%"
                  >
                    运费模板
                  </th>
                  <th
                      v-if="goodsPlace != 2"
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="运费模板"
                      style="width: 7%"
                  >
                    运费模板
                  </th>
                  <th
                      v-if="goodsPlace != 2"
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="库存"
                      style="width: 5%"
                  >
                    库存
                  </th>
                  <th
                      v-if="(goodsPlace == 2 || params.isJd == 1)"
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="库存"
                      style="width: 7%"
                  >
                    库存
                  </th>
                  <th
                      v-if="(goodsPlace == 2 || params.isJd == 1)"
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="商品来源"
                      style="width: 10%"
                  >
                    商品来源
                  </th>
                  <th
                      v-if="!(goodsPlace == 2 || params.isJd == 1)"
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="商品来源"
                      style="width: 6%"
                  >
                    商品来源
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="状态"
                      style="width: 6%"
                  >
                    状态
                  </th>
                  <th
                      class="text-center min-w-70px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="更新时间"
                      style="width: 9%"
                  >
                    创建时间
                  </th>
                  <th
                      class="text-center min-w-70px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="操作"
                      style="width: 3%"
                  >
                    操作
                  </th>
                </tr>
                </thead>
                <tbody class="fw-semibold text-gray-600">
                <tr
                    v-for="(item, index) in rows"
                    :class="{ old: index % 2 == 0, even: index % 2 != 0 }"
                    :key="index"
                >

                  <!-- 复选按钮 -->
                  <td>
                    <div
                        class="form-check form-check-sm form-check-custom form-check-solid"
                    >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                          @click="addIdArr(item.id)"
                          :checked="idArrs.includes(item.id)"
                      />
                    </div>
                  </td>
                  <!-- 商品信息 -->
                  <td style="padding-right: 0">
                    <div
                        class="d-flex align-items-center"
                        @click="
                          openGoodInfo(
                            item.isJD == 1 ? item.skuId : item.id,
                            item.isJD
                          )
                        "
                    >
                      <a href="javascript:void(0)" class="symbol symbol-50px">

                        <img
                            class="symbol-label lozad"
                            v-if="item.picUrl.indexOf('http') == 0"
                            :src="`${item.picUrl}`"
                        />
                        <img
                            class="symbol-label lozad"
                            v-else
                            :src="`https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com${item.picUrl}`"
                        />
                      </a>
                      <div
                          style="
                            max-width: 170px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            margin-left: 10px;
                          "
                      >
                        <a
                            href="javascript:void(0)"
                            :title="item.title"
                            class="text-gray-800 text-hover-primary fs-5 "
                            data-kt-ecommerce-product-filter="product_name"
                        >{{ item.title }}</a
                        >
                      </div>
                    </div>
                  </td>
                  <!-- 分类 -->
                  <td class="text-center pe-0">
                    <div
                        class=""
                        style="font-size: 90%"
                        v-html="item.categoryName"
                    ></div>
                  </td>
                  <!-- 市场价 -->
                  <td
                      class="text-center pe-0 text-black "
                  >
                      <span
                          class=""
                          style="
                          font-size: 90%;
                        "
                      >
                        {{ item.price | format }}
                      </span>
                  </td>
                  <!-- 分销价 -->
                  <td
                      class="text-center pe-0 text-black "
                  >
                      <span
                          class=""
                          style="
                          font-size: 90%;
                        "
                      >
                        {{ item.supplPrice | format }}
                      </span>
                  </td>
                  <!-- 全国商品池 -->
                  <td class="text-center pe-0" v-if="!(goodsPlace == 2 && params.isJd == 1)">
                      <span
                          v-if="item.isQuan == 1 && item.status==0"
                          class=" text-warning"
                          style="font-size: 90%"
                      >待审核</span
                      >
                    <span
                        v-if="item.isQuan == 1 && item.status==1"
                        class=" text-success"
                        style="font-size: 90%"
                    >审核通过</span
                    >
                    <span
                        v-if="item.isQuan == 1 && item.status==2"
                        class=" text-danger"
                        style="font-size: 90%"
                    >审核未通过</span
                    >
                    <span
                        v-if="item.isQuan == 2 || (item.status==-1)"
                        class="text-danger"
                        style="font-size: 90%"
                    >不加入</span
                    >
                  </td>
                  <!-- 全国分销价 -->
                  <td class="text-center pe-0" v-if="!(goodsPlace == 2 || params.isJd == 1)">
                    <div
                        class="text-center pe-0 text-black  min-w-75px"
                        v-if="item.quanSupplyPrice != '-'"
                        @click="openQuanSupplyPriceDialog(item)"
                    >
                        <span
                            class=""
                            style="
                            font-size: 90%;
                          "
                            v-html="item.quanSupplyPrice"
                        ></span>
                    </div>
                    <div class="text-center" v-else>-</div>
                  </td>
                  <!-- 利润率 -->
                  <td class="text-center pe-0">
                    <span
                        class=""
                        style="font-size: 90%"
                        v-html="item.rightRate"
                    ></span>
                    <span
                        v-if="goodsPlace == 2 && params.isJd == 1"
                        class=""
                        style="font-size: 90%"
                        v-html="item.maxRightRate"
                    ></span>
                  </td>
                  <!-- 销售价 -->
                  <td class="text-center pe-0" v-if="!(goodsPlace == 2 || params.isJd == 1)">
                      <span
                          class=" text-black  text-hover-info border-hover-info cursor-pointer"
                          @click="openSalePriceDialog(item)"
                          style="
                          font-size: 90%;
                        "
                      >
                        {{ item.salePrice | format }}</span
                      >
                  </td>
                  <!-- 销量 -->
                  <td class="text-center pe-0">
                      <span class="" style="font-size: 90%" v-if="item.saleNum">{{
                          item.saleNum
                        }}</span>
                    <span class="" style="font-size: 90%" v-else>0</span>
                  </td>
                  <!-- 运费模板 -->
                  <td class="text-center pe-0">
                      <span
                          class=""
                          style="
                          word-break: break-all;
                          overflow: hidden;
                          font-size: 90%;
                          display: -webkit-box;
                          -webkit-line-clamp: 2;
                          -webkit-box-orient: vertical;
                        "
                          :title="item.freightId"
                      >
                        {{ item.freightId }}
                      </span>
                  </td>
                  <!-- 库存 -->
                  <td class="text-center pe-0">
                      <span
                          class=""
                          @click="editStock(item)"
                          style="
                          font-size: 90%;
                        "
                      >{{ item.stock || 0 }}</span
                      >
                  </td>
                  <!-- 商品来源 -->
                  <td class="text-center pe-0">
                    <span v-if="(goodsPlace == 2 && params.isJd == 2)" style="font-size: 90%">
                      平台优选
                    </span>
                    <span v-if="(goodsPlace == 2 && params.isJd == 1)" style="font-size: 90%">
                      京东精品
                    </span>
                    <span v-if="!(goodsPlace == 2 || params.isJd == 1)" style="font-size: 90%">{{
                        item.type
                    }}</span>
                  </td>
                  <!-- 状态 -->
                  <td class="text-center pe-0">
                    <span
                        class="text-center "
                    >
                      <span
                          class="badge fs-7 "
                          :class="item.isShow != '未上架'? 'badge-light-success' :'badge-light-danger'"
                          style="
                          display: block;
                          text-align: center;
                          margin: 0 auto;
                        "
                          v-html="item.isShow"
                      >
                      </span>

                    </span>
                  </td>
                  <!-- 更新时间 -->
                  <td class="text-center pe-0">
                    <span class="">{{ item.crTime }}</span>
                  </td>
                  <td>
                    <el-button type="text" class="custom-text-button text-hover-info text-black"
                             @click="openDeleteWindow(item.id)">删除</el-button>
                  </td>
                </tr>
                </tbody>
              </table>
              <Pagination
                  :total-items="totalCount"
                  @page-change="pageChange"
              ></Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  商品详情  -->
    <ModalDialog title="商品详情" id="goodInfo" ref="goodInfo" width="1000px">
      <GoodInfoComponent ref="goodInfoComponent"></GoodInfoComponent>
    </ModalDialog>
    <ModalDialog title="配置自有商品池" id="configureSjGoods" ref="configureSjGoods" width="98%">
      <template v-slot:header-buttons>
        <button class="btn btn-primary btn-sm" @click="addGoods">
          批量添加进分组
        </button>
      </template>
      <OwnGoodsList ref="OwnGoodsList" :groupId="goodsGroupId" @setArrId="setIdArr" @setIsJd="setIsJd"></OwnGoodsList>
    </ModalDialog>
    <ModalDialog :title="userRole.includes('vipMember')?'配置商会商品池商品':'配置商品池商品'" id="configurePoolGoods" ref="configurePoolGoods" width="98%">
      <template v-slot:header-buttons>
        <button class="btn btn-primary btn-sm" @click="addGoods">
          批量添加进分组
        </button>
      </template>
      <PoolGoodsList ref="ChooseGoods" :groupId="goodsGroupId" @setPoolIdArr="setIdArr" @setPoolIsJd="setIsJd"></PoolGoodsList>
    </ModalDialog>
    <!-- 推送 -->
    <ModalDialog
        title="批量推送"
        id="pushGoodsAll"
        ref="pushGoodsAll"
        width="850px"
    >
      <div>
        <el-form ref="form" :model="pushGoodsObj" label-width="80px">
          <el-form-item label="推送目标" :required="true">
            <el-checkbox-group v-model="pushGoodsObj.selectionObjects">
              <el-checkbox label="shop">商城</el-checkbox>
              <el-checkbox label="topic">活动</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="已选商品">
            <span style="color: red">{{ idArrs.length }} </span> 件商品
          </el-form-item>
          <el-form-item
              label="销售价"
              :required="pushGoodsObj.salePriceChange == '1'"
          >
            <el-radio-group v-model="pushGoodsObj.salePriceChange">
              <el-radio label="1">按照</el-radio>
            </el-radio-group>
            &nbsp;&nbsp;&nbsp;
            <el-select
                v-model="pushGoodsObj.salePriceDto.salePriceReference"
                style="width: 150px"
                placeholder="请选择"
                :disabled="pushGoodsObj.salePriceChange != 1"
                size="small" @change="changeSalePrice"
            >
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
            &nbsp;
            <el-select
                v-model="pushGoodsObj.salePriceDto.salePriceAdjust"
                style="width: 150px"
                placeholder="请选择"
                size="small"
                :disabled="pushGoodsObj.salePriceChange != 1"
            >
              <el-option
                  v-for="item in adjustOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
            &nbsp;
            <el-input
                v-model="pushGoodsObj.salePriceDto.salePriceNum"
                placeholder="请输入内容"
                size="small"
                style="width: 150px"
                :disabled="pushGoodsObj.salePriceChange != 1"
            ></el-input>
            &nbsp;
            <el-select
                style="width: 100px"
                v-model="pushGoodsObj.salePriceDto.salePriceUnit"
                placeholder="请选择"
                size="small"
                :disabled="pushGoodsObj.salePriceChange != 1"
            >
              <el-option
                  v-for="item in unitOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
            <br/>
            <el-radio-group v-model="pushGoodsObj.salePriceChange">
              <el-radio label="2">不改变</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-button
          type="primary"
          style="float: right; margin-right: 20px"
          @click="nextPush"
      >下一步<i class="el-icon-arrow-right el-icon--right"></i
      ></el-button>
    </ModalDialog>
    <ModalDialog
        title="选择具体推送目标"
        id="pushGrounp"
        ref="pushGrounp"
        :width="pushGoodsObj.selectionObjects.length * 400 + 'px'"
    >
      <div style="display: flex">
        <div
            style="width: 400px; flex: 1; height: 400px; overflow-y: scroll"
            v-if="pushGoodsObj.selectionObjects.includes('shop')"
        >
          <h3>商城</h3>
          <div>
            <el-tree
                :data="shopProps"
                show-checkbox
                node-key="id"
                ref="shopTree"
            >
            </el-tree>
          </div>
        </div>
        <div
            style="width: 400px; flex: 1; height: 400px; overflow-y: scroll"
            v-if="pushGoodsObj.selectionObjects.includes('topic')"
        >
          <h3>活动</h3>
          <div>
            <el-tree
                :data="topicProps"
                ref="topicTree"
                node-key="id"
                show-checkbox
            >
            </el-tree>
          </div>
        </div>
      </div>
      <el-divider></el-divider>

      <el-button
          type="primary"
          style="float: right; margin-right: 20px"
          @click="submitPush"
      >提交&nbsp;&nbsp;<i class="el-icon-arrow-right el-icon--right"></i
      ></el-button>
      <el-button
          type="primary"
          style="float: right; margin-right: 20px"
          @click="$refs.pushGrounp.close()"
      >上一步<i class="el-icon-arrow-left el-icon--left"></i
      ></el-button>
    </ModalDialog>
  </div>
</template>
<script>
// 导入数据api
import {
  getReviewedPoolGoodsCountApi,
  getReviewedPoolGoodsListApi,
  getReviewedSjGoodsCountApi,
  getReviewedSjGoodsListApi,
  getStaffListApi
} from "@/api/goodsManageApi";

import GoodInfoComponent from "@/components/GoodInfoComponent";
// 页码指定跳转
import Pagination from "@/components/Pagination/index.vue";
import {getCategoryList} from "@/api/categoryApi";
// 导入组件
import SelectionGoods from "@/views/Goods/components/GoodsManage/components/SelectionedGoods/SelectionedGoodsList.vue";
import OwnGoodsList from "@/views/GroupManage/components/OwnGoods/OwnGoodsList.vue";
//import ChooseGoods from "@/views/ChooseGoods";
import PoolGoodsList from "@/views/GroupManage/components/PoolGoods/PoolGoodsList.vue";
import {
  addGoodsApi,
  deleteGoodsInGroupApi,
  getGoodsGroupByStaffIdApi,
  getWxShopByStaffIdApi,
  getWxTopicByStaffIdApi
} from "@/api/groupGoodsApi";
import {selectionGoodsApi} from "@/api/chooseGoodsApi";

export default {
  components: {
    PoolGoodsList,
    Pagination,
    GoodInfoComponent,
    SelectionGoods,
    OwnGoodsList,
    //ChooseGoods
  },
  data() {
    return {
      idArrs: [],
      goodsGroupId: "",
      staffArr: [], // 几级供应商
      selTypeId: 0, // 场景范围id
      selType: "",  // 搜索场景类型
      categoryOne: [],
      categoryTwo: [],
      idArr: [],
      rows: [],
      selArr: [],  // 场景范围数组
      checkboxInput: false,
      loading: false,
      // 拆线呢查询传参
      params: {
        current: 1,
        size: 10,
        title: "",
        categoryId1: "",
        categoryId2: "",
        isShow: 1,
        isTwoShow: 1,
        isJd: 2,
        goodsGroupId: 0,
        goodsStaffId: "",
        sevenNRR: "",
        maxRightRate: "",
        minRightRate: "",
        deliveryTime: ""
      },
      deliveryTime: [],
      // componentTransmission
      componentParams: {
        current: 1,
        size: 10,
        spuId: "",
        title: "",
        categoryId1: "",
        categoryId2: "",
        isShow: "",
        stock: "",
        isQuan: "",
        status: "",
        minStock: "",
        maxStock: "",
        isJd: 2,
        goodsGroupId: 0,
        shopId: "",
        topicId: "",
        goodsStaffId: ""
      },
      goodsPlace: 1,   // 1 自有 2 商品池
      totalCount: 999,
      first: true,
      isJd: 2,
      groupProps: [],
      shopProps: [],
      topicProps: [],
      unitOptions: [
        {
          value: "1",
          label: "%",
        },
        {
          value: "2",
          label: "元",
        },
      ],
      adjustOptions: [
        //{
        //  value: "1",
        //  label: "上调",
        //},
        {
          value: "2",
          label: "下降",
        },
      ],
      options: [
        //{
        //  value: "1",
        //  label: "销售价",
        //},
        {
          value: "2",
          label: "市场价",
        },
        {
          value: "3",
          label: "分销价",
        },
      ],
      pushGoodsObj: {
        topicIds: [],
        shopIds: [],
        selectionObjects: [],
        goodsIds: [],
        isJd: 1,
        salePriceChange: "2",
        salePriceDto: {
          salePriceReference: "2",
          salePriceAdjust: "2",
          salePriceNum: "",
          salePriceUnit: "1",
        },
      },
      userRole: localStorage.getItem("role")
    }
  },
  // 方法
  methods: {
    async submitPush(id) {
      this.pushGoodsObj.topicIds = [];
      this.pushGoodsObj.shopIds = [];
      this.pushGoodsObj.goodsGroupIds = [];
      this.pushGoodsObj.goodsIds = [];
      if (this.pushGoodsObj.selectionObjects.includes("topic")) {
        this.pushGoodsObj.topicIds = this.$refs.topicTree.getCheckedKeys();
        if (this.pushGoodsObj.topicIds.length == 0) {
          let index = this.pushGoodsObj.selectionObjects.indexOf("topic");
          this.pushGoodsObj.selectionObjects.splice(index, 1);
        }
      }
      if (this.pushGoodsObj.selectionObjects.includes("shop")) {
        this.pushGoodsObj.shopIds = this.$refs.shopTree.getCheckedKeys();
        if (this.pushGoodsObj.shopIds.length == 0) {
          let index = this.pushGoodsObj.selectionObjects.indexOf("shop");
          this.pushGoodsObj.selectionObjects.splice(index, 1);
        }
      }
      if (this.pushGoodsObj.selectionObjects.includes("group")) {
        this.pushGoodsObj.goodsGroupIds = this.$refs.groupTree.getCheckedKeys();
        if (this.pushGoodsObj.goodsGroupIds.length == 0) {
          let index = this.pushGoodsObj.selectionObjects.indexOf("group");
          this.pushGoodsObj.selectionObjects.splice(index, 1);
        }
      }
      if (this.pushGoodsObj.supplPriceChange == "2") {
        this.pushGoodsObj.supplPriceDto = {
          supplPriceReference: "",
          supplPriceAdjust: "",
          supplPriceNum: "",
          supplPriceUnit: "",
        };
      }
      if (this.pushGoodsObj.salePriceChange == "2") {
        this.pushGoodsObj.salePriceDto = {
          salePriceReference: "",
          salePriceAdjust: "",
          salePriceNum: "",
          salePriceUnit: "",
        };
      }
      this.pushGoodsObj.goodsIds = this.idArrs;
      this.pushGoodsObj.isJd = this.isJd;

      let arr = ["group", "shop", "topic"];
      let arr1 = [];
      arr.forEach((item) => {
        if (this.pushGoodsObj.selectionObjects.includes(item)) {
          arr1.push(item);
        }
      });
      let {data} = await selectionGoodsApi(this.pushGoodsObj);
      this.$message({
        message: "推送成功",
        type: "success",
      });
      this.$refs.pushGoodsAll.close();
      this.$refs.pushGrounp.close();
    },
    async nextPush() {
      if (this.pushGoodsObj.selectionObjects.length == 0) {
        this.$message({
          message: "请选择推送目标",
          type: "warning",
        });
        return;
      }

      this.$refs.pushGrounp.open();
    },
    async batchPush() {
      this.isJd = 2;
      if (this.goodsPlace === 1) {
        this.isJd = 2;
      } else {
        this.isJd = this.componentParams.isJd;
      }
      if (this.idArrs.length == 0) {
        return;
      }
      this.$refs.pushGoodsAll.open();
      this.shopProps = [];
      this.topicProps = [];
      this.groupProps = [];
      let {data: res1} = await getWxTopicByStaffIdApi();
      res1.forEach((item, index) => {
        this.topicProps.push({
          id: item.id,
          label: item.title,
        });
      });
      let {data: res2} = await getWxShopByStaffIdApi();
      res2.forEach((item, index) => {
        this.shopProps.push({
          id: item.id,
          label: item.name,
        });
      });
      let {data: res3} = await getGoodsGroupByStaffIdApi();
      res3.forEach((item, index) => {
        this.groupProps.push({
          id: item.id,
          label: item.name,
        });
      });

    },
    addGoods() {
      this.$confirm('确定将商品推送至当前分组?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let {data} = await addGoodsApi({
          goodsGroupIds: [this.goodsGroupId],
          isJd: this.componentParams.isJd,
          goodsIds: this.idArr
        })
        this.$refs.ChooseGoods.reloadIdArr();
        this.$refs.OwnGoodsList.reloadIdArr();
        this.$refs.configurePoolGoods.close();
        this.$refs.configureSjGoods.close();
        this.$message({
          type: 'success',
          message: '推送成功!'
        });
        console.log(this.goodsPlace)
        if (this.goodsPlace == 1){
          this.getSjGoodsList();
        } else {
          this.getPoolGoodsList();
        }
      }).catch(() => {

      });
    },
    configureGoods(item) {
      //this.params.isJd = item;
      this.componentParams.isJd = item;
      if (item === "1") {
        this.$refs.ChooseGoods.reloadIdArr();
        this.$refs.configurePoolGoods.open();
      } else if (item === "2") {
        this.$refs.OwnGoodsList.reloadIdArr();
        this.$refs.OwnGoodsList.getSjGoodsList();
        this.$refs.configureSjGoods.open();
      } else {
        this.$message.error("网络波动！请重新选择商品来源");
      }
    },
    // 选择商城还是选择活动等等
    changeQuery() {
      this.first = true
      if (this.goodsPlace == 1) {
        this.getSjGoodsList()
      } else {
        this.getPoolGoodsList()
      }
    },
    setIsJd(param) {
      this.componentParams = param
    },
    setIdArr(idArr) {
      this.idArr = idArr
    },
    checkboxInputEvent() {
      if (this.checkboxInput) {
        this.rows.forEach((e) => {
          if (!this.idArrs.includes(e.id)) {
            this.idArrs.push(e.id);
          }
        });
      } else {
        this.rows.forEach((e) => {
          let index = this.idArrs.indexOf(e.id);
          if (index !== -1) {
            this.idArrs.splice(index, 1);
          }
        });
      }
    },
    async addIdArr(id) {
      if (this.idArrs.includes(id)) {
        let index = this.idArrs.indexOf(id);
        if (index !== -1) {
          this.idArrs.splice(index, 1);
        }
      } else {
        this.idArrs.push(id);
      }
      let a = true;
      this.rows.forEach((e) => {
        if (!this.idArrs.includes(e.id)) {
          a = false;
        }
      });
      if (this.rows, length == 0) {
        a = false
      }
      this.checkboxInput = a;
    },
    openGoodInfo(id, isJd) {
      this.$refs.goodInfo.open();
      this.$refs.goodInfoComponent.openGoodsInfo(id, isJd);
      // console.log("打开商品详情",id);
    },
    // 分页查询 自有商品
    async getSjGoodsList() {
      this.checkboxInput = false;
      this.loading = true;
      this.params.goodsGroupId = this.$route.params.id;
      let {data} = await getReviewedSjGoodsListApi(this.params);
      this.loading = false;
      this.first = true;
      this.rows = data.rows;
      if (this.first) {
        this.getSjGoodsPageCount();
      }
      this.first = false;
      let a = true;
      this.rows.forEach((e) => {
        if (!this.idArrs.includes(e.id)) {
          a = false;
        }
      });
      if (this.rows, length == 0) {
        a = false
      }
      this.checkboxInput = a;
    },

    // 分页查询 商品池商品
    async getPoolGoodsList() {
      this.checkboxInput = false;
      this.loading = true;
      this.first = true;
      this.params.goodsGroupId = this.$route.params.id;
      let {data} = await getReviewedPoolGoodsListApi(this.params);
      this.loading = false;
      this.rows = data.rows;
      if (this.first) {
        this.getPoolGoodsPageCount();
      }
      this.first = false;
      let a = true;
      this.rows.forEach((e) => {
        if (!this.idArrs.includes(e.id)) {
          a = false;
        }
      });
      this.checkboxInput = a;
    },

    pageChange(currentPage, pageSize) {
      this.params.current = currentPage;
      this.params.size = pageSize;
      if (this.deliveryTime.length > 0) {
        this.params.deliveryTime = this.deliveryTime.join(',');
      } else {
        this.params.deliveryTime = "";
      }
      if (this.goodsPlace == 1) {
        this.getSjGoodsList();
      } else {
        this.getPoolGoodsList();
      }
    },
    async getSjGoodsPageCount() {
      let {data} = await getReviewedSjGoodsCountApi(this.params);
      this.totalCount = data;
    },
    async getPoolGoodsPageCount() {
      let {data} = await getReviewedPoolGoodsCountApi(this.params);
      this.totalCount = data;
    },
    toggleDropdown(index) {
      this.$nextTick(() => {
        const dropdown = document.getElementById(`dropdown-${index}`);
        new KTMenu(dropdown);
      });
    },
    // 筛选
    shaixuan() {
      this.idArrs = []
      this.first = true;
      if (this.deliveryTime.length > 0) {
        this.params.deliveryTime = this.deliveryTime.join(',');
      } else {
        this.params.deliveryTime = "";
      }
      if (this.goodsPlace == 1) {
        this.getSjGoodsList();
      } else {
        this.getPoolGoodsList();
      }
    },
    // 重置
    resetQuery (){
      this.deliveryTime= [];
      this.params = {
        current: 1,
        size: 10,
        title: "",
        categoryId1: "",
        categoryId2: "",
        isShow: 1,
        isTwoShow: 1,
        isJd: this.params.isJd,
        goodsGroupId: 0,
        goodsStaffId: "",
        sevenNRR: "",
        maxRightRate: "",
        minRightRate: "",
        deliveryTime: ""
      }
      this.first = true
      if (this.deliveryTime.length > 0) {
        this.params.deliveryTime = this.deliveryTime.join(',');
      } else {
        this.params.deliveryTime = "";
      }
      if (this.goodsPlace == 1) {
        this.getSjGoodsList();
      } else {
        this.getPoolGoodsList();
      }
    },
    async findCategory(index) {
      this.first = true;
      if (index == 1) {
        if (!this.params.categoryId1){
          this.params.categoryId1 = 0;
          this.params.categoryId2 = "";
          this.categoryTwo = [];
        }
        let {data} = await getCategoryList({
          parentId: this.params.categoryId1,
        });
        if (this.params.categoryId1 !== 0) {
          this.categoryTwo = data.list;
        }
      }
      if (this.params.categoryId1 === 0){
        this.params.categoryId1 = "";
      }
      if (this.deliveryTime.length > 0) {
        this.params.deliveryTime = this.deliveryTime.join(',');
      } else {
        this.params.deliveryTime = "";
      }
      if (this.goodsPlace == 1) {
        this.getSjGoodsList();
      } else {
        this.getPoolGoodsList();
      }
    },
    //初始化分类
    async initCategory() {
      let {data} = await getCategoryList({
        parentId: 0,
      });
      this.categoryOne = data.list;
    },
    // 点击自由商品和商品池商品
    ownReviewedGoods(goodsPlace, place) {
      this.idArrs = []
      this.idArr = []
      this.selType = goodsPlace == 1 ? "" : "group"
      this.selTypeId = 0;
      //this.selType="group";
      this.componentParams.goodsGroupId = "0";
      this.componentParams.shopId = "";
      this.componentParams.topicId = "";
      this.goodsPlace = goodsPlace;
      if (place) {
        this.componentParams.isJd = place;
        this.params.isJd = place;
      } else {
        this.componentParams.isJd = 2;
        this.params.isJd = 2;
      }
      this.params.current = 1;
      if (goodsPlace == 1) {
        this.getSjGoodsList();
      } else {
        this.getPoolGoodsList();
      }
    },
    async getStaffList() {
      let {data} = await getStaffListApi();
      this.staffArr = data;
    },
    // 父类index使用
    createGoods() {
    },
    // 按照价格改变时 调整方式需要改变
    changeSalePrice () {
      if (this.pushGoodsObj.salePriceDto.salePriceReference == 2) {
        this.pushGoodsObj.salePriceDto.salePriceAdjust = "2";
        this.adjustOptions = [
          {
            value: "2",
            label: "下降",
          },
        ]
      } else {
        this.adjustOptions = [
          {
            value: "1",
            label: "上调",
          },
          {
            value: "2",
            label: "下降",
          },
        ]
      }
    },
    // 打开弹窗并删除
    openDeleteWindow (goodId){
      let msg = "";
      if (goodId) {
        msg = "你是否删除该商品";
        this.idArrs = [goodId];
      } else {
        msg = "你是否删除选中的所有商品";
      }
      let arr = Array.from(this.idArrs)
      let groupId = [this.goodsGroupId]
      this.$confirm(`${msg}`, `是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
        type: "warning",
      }).then(async () => {
        await deleteGoodsInGroupApi({
          goodsIds: arr,
          goodsGroupIds: groupId,
        });
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        if (this.goodsPlace == 1) {
          this.getSjGoodsList();
        } else {
          this.getPoolGoodsList();
        }
      }).catch(() => {
      });

    },
    // 将多选改成单选
    setTree(data,typeName){
      this.$refs[typeName].setCheckedKeys([]);
      if (data){
        this.$refs[typeName].setCheckedNodes([data]);
      }
    },
    groupTreeNodeClick(data){
      this.setTree(data,"groupTree")
    },
    shopTreeNodeClick(data){
      this.setTree(data,"shopTree")
    },
    topicTreeNodeClick(data){
      this.setTree(data,"topicTree")
    },
  },
  // 初始化这个方法
  created() {
    this.initCategory();
    this.getStaffList();
    if (this.$route.params.id) {
      this.params.goodsGroupId = this.$route.params.id;
      this.goodsGroupId = this.$route.params.id;
      this.getSjGoodsList();
    }
  },

  updated() {
    // 当数据更新后，可以在此处手动初始化下拉菜单插件
    this.rows.forEach((_, index) => {
      this.toggleDropdown(index);
    });
  },
};
</script>
<!--组件样式-->
<style scoped>
.borderHover:hover {
  border-color: rgb(0, 158, 247) !important;
}

/* 修改滚动条样式 */
.table-responsive::-webkit-scrollbar {
  width: 20px; /* 设置滚动条宽度 */
  height: 15px;
  background-color: #F5F5F5; /* 设置滚动条背景颜色 */
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #888; /* 设置滚动条滑块颜色 */
  border-radius: 5px; /* 设置滚动条滑块圆角 */
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* 设置滚动条滑块在鼠标悬停时的颜色 */
}

</style>
