<template>
  <div style="min-height: 89vh">
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <div
        style="max-width: 98%; flex-direction: row !important; display: flex"
        id="kt_content_container"
        class="container"
      >
        <!-- 搜索开始 -->
        <div
          class="flex-column flex-lg-row-auto w-100 w-lg-250px w-xxl-325px mb-8 mb-lg-0 me-lg-9 me-5"
        >
          <form action="#" id="category_form1">
            <div class="card">
              <div class="card-body" style="padding: 1.2rem 1rem">
<!--                v-if="!userRole.includes('vipMember')"-->
                <div class="mb-5"  v-show="!userRole.includes('vipMember')">
                  <div class="nav-group nav-group-fluid">
                    <label>
                      <input
                        type="radio"
                        class="btn-check"
                        name="goodsPlace"
                        @click="changIsJd(1)"
                        value="1"
                        ref="goodsPlace1"
                      />
                      <span
                        class="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4"
                      >
                        京东货源
                      </span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        class="btn-check"
                        name="goodsPlace"
                        @click="changIsJd(2)"
                        value="2"
                        checked="checked"
                        ref="goodsPlace2"
                      />
                      <span
                        class="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4"
                      >
                        厂供货源
                      </span>
                    </label>
                  </div>
                </div>
                <div class="separator separator-dashed my-8"></div>
                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark"
                    >商品标题</label
                  >
                  <div class="position-relative">
                    <span
                      class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <path
                            d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                            opacity="0.3"
                          ></path>
                          <path
                            d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                          ></path>
                        </g>
                      </svg>
                    </span>
                    <input
                      id="sTitle"
                      type="text"
                      class="form-control form-control-solid ps-10"
                      name="search"
                      v-model="params.title"
                      value=""
                      placeholder="请输入需要查询的商品标题名称"
                    />
                  </div>
                </div>
                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark"
                    >一级分类</label
                  >
                  <select
                    class="form-select form-select-solid"
                    v-model="params.categoryId1"
                    data-placeholder="全部"
                    @change="findCategory(1)"
                  >
                    <option value="">全部</option>
                    <option
                      v-for="(item, index) in categoryOne"
                      :key="index"
                      :value="item.categoryId"
                    >
                      {{ item.fullName }}
                    </option>
                  </select>
                </div>
                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark"
                    >二级分类</label
                  >
                  <select
                    class="form-select form-select-solid"
                    v-model="params.categoryId2"
                    @change="findCategory(2)"
                    data-placeholder="全部"
                  >
                    <option value="">全部</option>
                    <option
                      v-for="(item, index) in categoryTwo"
                      :key="index"
                      :value="item.categoryId"
                    >
                      {{ item.fullName }}
                    </option>
                  </select>
                </div>
                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark"
                    >分销价区间</label
                  >
                  <div class="row">
                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                      <input
                        type="text"
                        id="minSupplyPrice"
                        name="minSupplyPrice"
                        class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        v-model="params.minPoolSupplPrice"
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                        placeholder="最小值"
                        value=""
                      />
                      <div
                        class="fv-plugins-message-container invalid-feedback"
                      ></div>
                    </div>
                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                      <input
                        type="text"
                        id="maxSupplyPrice"
                        name="maxSupplyPrice"
                        class="form-control form-control-lg form-control-solid"
                        v-model="params.maxPoolSupplPrice"
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                        placeholder="最大值"
                        value=""
                      />
                      <div
                        class="fv-plugins-message-container invalid-feedback"
                      ></div>
                    </div>
                  </div>
                </div>

                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark"
                    >利润率区间</label
                  >
                  <div class="row">
                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                      <input
                        type="text"
                        id="minProfitMargin"
                        name="minProfitMargin"
                        class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        v-model="params.minQuanRightRate"
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                        placeholder="最小值"
                        value=""
                      />
                      <div
                        class="fv-plugins-message-container invalid-feedback"
                      ></div>
                    </div>
                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                      <input
                        type="text"
                        id="maxProfitMargin"
                        name="maxProfitMargin"
                        class="form-control form-control-lg form-control-solid"
                        v-model="params.maxQuanRightRate"
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                        placeholder="最大值"
                        value=""
                      />
                      <div
                        class="fv-plugins-message-container invalid-feedback"
                      ></div>
                    </div>
                  </div>
                </div>

                <div class="d-flex align-items-center justify-content-end">
                  <a
                    @click="shaixuan"
                    href="javascript:void(0);"
                    class="btn btn-primary"
                    >筛选</a
                  >&nbsp;&nbsp;&nbsp;
                  <a
                    @click="resetQuery"
                    href="javascript:void(0);"
                    class="btn btn-active-light-primary btn-color-gray-400 me-3"
                    >重置</a
                  >
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- 列表开始 -->
        <div
          class="card mb-5 mb-xl-8"
          style="margin-left: 3px; width: 100%"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          v-loading="loading"
        >
          <div class="card-body py-3">
            <div class="table-responsive">
              <table
                class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer"
                id="kt_ecommerce_products_table"
              >
                <thead>
                  <tr
                    class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
                  >
                    <th
                      class="w-10px pe-2 sorting_disabled"
                      rowspan="1"
                      colspan="1"
                      aria-label=""
                      style="width: 29.8906px"
                    >
                      <div
                        class="form-check form-check-sm form-check-custom form-check-solid me-3"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          data-kt-check="true"
                          data-kt-check-target="#kt_ecommerce_products_table .form-check-input"
                          value="TRUE"
                          v-model="checkboxInput"
                          @change="checkboxInputEvent"
                        />
                      </div>
                    </th>
                    <th
                      class="min-w-200px"
                      tabindex="0"
                      aria-controls="kt_ecommerce_products_table"
                      rowspan="1"
                      colspan="1"
                      aria-label="Product: activate to sort column ascending"
                      style="width: 212.141px"
                    >
                      商品信息
                    </th>
                    <th
                      class="text-center"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="分类"
                      style="width: 80px"
                    >
                      分类
                    </th>
                    <th
                      class="text-center"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="市场价"
                      style="width: 80px"
                    >
                      市场价
                    </th>
                    <th
                      class="text-center"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="分销价"
                      style="width: 80px"
                    >
                      分销价
                    </th>
                    <th
                      class="text-center"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="利润率"
                      style="width: 80px"
                    >
                      利润率
                    </th>
                    <th
                      class="text-center"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="销售方式"
                      style="width: 80px"
                    >
                      销售方式
                    </th>
                    <th
                      class="text-center"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="库存"
                      style="width: 80px"
                      v-if="params.isJd != 1"
                    >
                      库存
                    </th>
                  </tr>
                </thead>
                <tbody class="fw-semibold text-gray-600">
                  <tr
                    v-for="(item, index) in rows"
                    :class="{ old: index % 2 == 0, even: index % 2 != 0 }"
                    :key="index"
                  >
                    <!-- 复选按钮 -->
                    <td>
                      <div
                        class="form-check form-check-sm form-check-custom form-check-solid"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                          @click="addIdArr(item.id)"
                          :checked="idArr.includes(item.id)"
                        />
                      </div>
                    </td>
                    <!-- 商品信息 -->
                    <td>
                      <div class="d-flex align-items-center">
                        <a
                          href="javascript:void(0);"
                          class="symbol symbol-50px"
                        >
                          <img
                            class="symbol-label lozad"
                            v-if="item.picUrl.indexOf('http') == 0"
                            :src="`${item.picUrl}`"
                          />
                          <img
                            class="symbol-label lozad"
                            v-else
                            :src="`https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com${item.picUrl}`"
                          />
                        </a>
                        <div class="ms-5">
                          <a
                            href="javascript:void(0);"
                            class="text-gray-800 text-hover-primary fs-5"
                            data-kt-ecommerce-product-filter="product_name"
                            @click="
                              openGoodInfo(
                                item.isJD == 1 ? item.skuId : item.id,
                                item.isJD
                              )
                            "
                            >{{ item.title }}</a
                          >
                        </div>
                      </div>
                    </td>
                    <!-- 分类 -->
                    <td class="text-center pe-0">
                      <span
                        v-html="item.categoryName"
                        v-if="item.categoryName"
                      >
                      </span>
                      <span
                        v-else
                        v-html="
                          [
                            item.categoryId1Name,
                            item.categoryId2Name,
                            item.categoryId3Name,
                          ].join('<br>')
                        "
                      >
                      </span>
                    </td>
                    <!-- 市场价 -->
                    <td class="text-center pe-0">
                      <span>{{ item.price | format }} </span>
                    </td>
                    <!-- 分销价 -->
                    <td class="text-center pe-0">
                      <span>{{
                        item.supplPrice | format
                      }}</span>
                    </td>
                    <!-- 利润率 -->
                    <td class="text-center pe-0" v-if="params.isJd == 1">
                      <span>{{ item.maxRightRate }}</span>
                    </td>
                    <td class="text-center pe-0" v-if="params.isJd == 2" v-html="item.rightRate">
                    </td>
                    <!-- 销售方式 -->
                    <td class="text-center pe-0" v-if="params.isJd == 1">
                      <span>{{ item.buyType }}</span>
                    </td>
                    <td class="text-center pe-0" v-if="params.isJd == 2">
                      <span v-if="item.startNum == 1"> 一件代发 </span>
                      <span v-else> {{item.startNum}}件起售 </span>
                    </td>
                    <!-- 库存 -->
                    <td class="text-center pe-0" v-if="params.isJd != 1">
                      <span>{{ item.stock || 0 }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Pagination
                :total-items="totalCount"
                @page-change="pageChange"
                @page-change-size="pageChangeSize"
              ></Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 商品详情 -->
    <ModalDialog title="商品详情" id="goodInfo" ref="goodInfo" width="1000px">
      <GoodInfoComponent ref="goodInfoComponent"></GoodInfoComponent>
    </ModalDialog>
    <!-- 推送 -->
    <ModalDialog
      title="挑选上架"
      id="pushGoodsAll"
      ref="pushGoodsAll"
      width="850px"
    >
      <div>
        <el-form ref="form" :model="pushGoodsObj" label-width="80px">
          <el-form-item label="推送目标" :required="true">
            <el-checkbox-group v-model="pushGoodsObj.selectionObjects">
              <el-checkbox label="group">分组</el-checkbox>
              <el-checkbox label="shop">商城</el-checkbox>
              <el-checkbox label="topic">活动</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="已选商品">
            <span style="color: red">{{ state?"1":idArr.length }} </span> 件商品
          </el-form-item>
          <el-form-item
            label="销售价"
            :required="pushGoodsObj.salePriceChange == '1'"
          >
            <el-radio-group v-model="pushGoodsObj.salePriceChange">
              <el-radio label="1">按照</el-radio>
            </el-radio-group>
            &nbsp;&nbsp;&nbsp;
            <el-select
              v-model="pushGoodsObj.salePriceDto.salePriceReference"
              style="width: 150px"
              placeholder="请选择"
              :disabled="pushGoodsObj.salePriceChange != 1"
              size="small"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            &nbsp;
            <el-select
              v-model="pushGoodsObj.salePriceDto.salePriceAdjust"
              style="width: 150px"
              placeholder="请选择"
              size="small"
              :disabled="pushGoodsObj.salePriceChange != 1"
            >
              <el-option
                v-for="item in adjustOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            &nbsp;
            <el-input
              v-model="pushGoodsObj.salePriceDto.salePriceNum"
              placeholder="请输入内容"
              size="small"
              style="width: 150px"
              :disabled="pushGoodsObj.salePriceChange != 1"
            ></el-input>
            &nbsp;
            <el-select
              style="width: 100px"
              v-model="pushGoodsObj.salePriceDto.salePriceUnit"
              placeholder="请选择"
              size="small"
              :disabled="pushGoodsObj.salePriceChange != 1"
            >
              <el-option
                v-for="item in unitOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <br />
            <el-radio-group v-model="pushGoodsObj.salePriceChange">
              <el-radio label="2">不改变</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-button
        type="primary"
        style="float: right; margin-right: 20px"
        @click="nextPush"
        >下一步<i class="el-icon-arrow-right el-icon--right"></i
      ></el-button>
    </ModalDialog>
    <ModalDialog
      title="选择具体推送目标"
      id="pushGrounp"
      ref="pushGrounp"
      :width="pushGoodsObj.selectionObjects.length * 400 + 'px'"
    >
      <div style="display: flex">
        <div
          style="width: 400px; flex: 1; height: 400px; overflow-y: scroll"
          v-if="pushGoodsObj.selectionObjects.includes('group')"
        >
          <h3>分组</h3>
          <div>
            <el-tree
              :data="groupProps"
              show-checkbox
              node-key="id"
              ref="groupTree"
            >
            </el-tree>
          </div>
        </div>
        <div
          style="width: 400px; flex: 1; height: 400px; overflow-y: scroll"
          v-if="pushGoodsObj.selectionObjects.includes('shop')"
        >
          <h3>商城</h3>
          <div>
            <el-tree
              :data="shopProps"
              show-checkbox
              node-key="id"
              ref="shopTree"
            >
            </el-tree>
          </div>
        </div>
        <div
          style="width: 400px; flex: 1; height: 400px; overflow-y: scroll"
          v-if="pushGoodsObj.selectionObjects.includes('topic')"
        >
          <h3>活动</h3>
          <div>
            <el-tree
              :data="topicProps"
              ref="topicTree"
              node-key="id"
              show-checkbox
            >
            </el-tree>
          </div>
        </div>
      </div>
      <el-divider></el-divider>

      <el-button
        type="primary"
        style="float: right; margin-right: 20px"
        @click="submitPush"
        >提交&nbsp;&nbsp;<i class="el-icon-arrow-right el-icon--right"></i
      ></el-button>
      <el-button
        type="primary"
        style="float: right; margin-right: 20px"
        @click="$refs.pushGrounp.close()"
        >上一步<i class="el-icon-arrow-left el-icon--left"></i
      ></el-button>
    </ModalDialog>
  </div>
</template>
<script>
import {
  getChooseGoodsListApi,
  getChooseGoodsListCountApi,
  selectionGoodsApi,
} from "@/api/chooseGoodsApi";
import { unSelectedPoolGoodsApi,unSelectedPoolGoodsCountApi } from "@/api/goodsManageApi";
import Pagination from "@/components/Pagination";
import GoodInfoComponent from "@/components/GoodInfoComponent";
import { getCategoryList } from "@/api/categoryApi";
import {
  getGoodsGroupByStaffIdApi,
  getWxShopByStaffIdApi,
  getWxTopicByStaffIdApi,
} from "@/api/groupGoodsApi";
import goodsManage from "@/views/Goods/components/GoodsManage/index.vue";

export default {
  name:"PoolGoods",
  components: {
    Pagination,
    GoodInfoComponent,
  },
  props: {
    groupId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      groupProps: [],
      shopProps: [],
      topicProps: [],
      unitOptions: [
        {
          value: "1",
          label: "%",
        },
        {
          value: "2",
          label: "元",
        },
      ],
      adjustOptions: [
        {
          value: "1",
          label: "上调",
        },
        {
          value: "2",
          label: "下降",
        },
      ],
      options: [
        {
          value: "1",
          label: "销售价",
        },
        {
          value: "2",
          label: "市场价",
        },
        {
          value: "3",
          label: "分销价",
        },
      ],
      salePriceReference: "1",
      checkboxInput: true,
      idArr: [],
      categoryOne: [],
      categoryTwo: [],
      loading: false,
      pushGoodsObj: {
        topicIds: [],
        shopIds: [],
        goodsGroupIds: [],
        selectionObjects: [],
        goodsIds: [],
        isJd: 1,
        salePriceChange: "2",
        supplPriceChange: "2",
        salePriceDto: {
          salePriceReference: "1",
          salePriceAdjust: "1",
          salePriceNum: "",
          salePriceUnit: "1",
        },
        supplPriceDto: {
          supplPriceReference: "1",
          supplPriceAdjust: "1",
          supplPriceNum: "",
          supplPriceUnit: "1",
        },
      },
      rows: [],
      params: {
        current: 1,
        size: 10,
        spuId: "",
        title: "",
        categoryId1: "",
        categoryId2: "",
        isShow: 1,
        isTwoShow: 1,
        isJd: 2,
        goodsGroupId: this.groupId,
        stock: "",
        isQuan: "",
        goodsStaffId: "",
        minStock: "",
        maxStock: "",
        sevenNRR: "",
        maxRightRate: "",
        minRightRate: "",
        minQuanRightRate:"",
        maxQuanRightRate: "",
        minPoolSupplPrice: "",
        maxPoolSupplPrice: "",
        deliveryTime: ""
      },
      totalCount: 99999,
      first: true,
      goodsId: "",
      isJd: 1,
      id: "",
      state:"",
      goodsInfo: [{
        id: "",
        isJd: ""
      }],
      userRole:JSON.parse(localStorage.getItem('role'))
    };
  },
  methods: {
    // 将id和来源都传给父组件
    setPoolGoodsInfo() {
      if (!this.goodsInfo) {
        this.idArr.forEach((id) => {
          this.goodsInfo.push({
            id: id,
            isJd: this.params.isJd
          });
        });
        this.$emit("setPoolGoodsInfo", this.goodsInfo);
      } else {
        const tempGoodsInfo = [];
        this.idArr.forEach((id) => {
          tempGoodsInfo.push({
            id: id,
            isJd: this.params.isJd
          });
        });
        this.goodsInfo = tempGoodsInfo;
        this.$emit("setPoolGoodsInfo", this.goodsInfo);
      }
    },
    reloadIdArr(){
      this.idArr = [];
      this.$emit("setPoolIdArr",this.idArr)
      this.resetQuery()
    },
    async buyGoods(id, isJd) {},
    async pushGood(id,state) {
      this.state=1;
      this.id = id;
      this.$refs.pushGoodsAll.open();
      this.shopProps = [];
      this.topicProps = [];
      this.groupProps = [];
      let { data: res1 } = await getWxTopicByStaffIdApi();
      res1.forEach((item, index) => {
        this.topicProps.push({
          id: item.id,
          label: item.title,
        });
      });
      let { data: res2 } = await getWxShopByStaffIdApi();
      res2.forEach((item, index) => {
        this.shopProps.push({
          id: item.id,
          label: item.name,
        });
      });
      let { data: res3 } = await getGoodsGroupByStaffIdApi();
      res3.forEach((item, index) => {
        this.groupProps.push({
          id: item.id,
          label: item.name,
        });
      });
    },
    async submitPush(id) {
      this.pushGoodsObj.topicIds = [];
      this.pushGoodsObj.shopIds = [];
      this.pushGoodsObj.goodsGroupIds = [];
      this.pushGoodsObj.goodsIds = [];
      if (this.pushGoodsObj.selectionObjects.includes("topic")) {
        this.pushGoodsObj.topicIds = this.$refs.topicTree.getCheckedKeys();
        if (this.pushGoodsObj.topicIds.length == 0) {
          let index = this.pushGoodsObj.selectionObjects.indexOf("topic");
          this.pushGoodsObj.selectionObjects.splice(index, 1);
        }
      }
      if (this.pushGoodsObj.selectionObjects.includes("shop")) {
        this.pushGoodsObj.shopIds = this.$refs.shopTree.getCheckedKeys();
        if (this.pushGoodsObj.shopIds.length == 0) {
          let index = this.pushGoodsObj.selectionObjects.indexOf("shop");
          this.pushGoodsObj.selectionObjects.splice(index, 1);
        }
      }
      if (this.pushGoodsObj.selectionObjects.includes("group")) {
        this.pushGoodsObj.goodsGroupIds = this.$refs.groupTree.getCheckedKeys();
        if (this.pushGoodsObj.goodsGroupIds.length == 0) {
          let index = this.pushGoodsObj.selectionObjects.indexOf("group");
          this.pushGoodsObj.selectionObjects.splice(index, 1);
        }
      }
      if (this.pushGoodsObj.supplPriceChange == "2") {
        this.pushGoodsObj.supplPriceDto = {
          supplPriceReference: "",
          supplPriceAdjust: "",
          supplPriceNum: "",
          supplPriceUnit: "",
        };
      }
      if (this.pushGoodsObj.salePriceChange == "2") {
        this.pushGoodsObj.salePriceDto = {
          salePriceReference: "",
          salePriceAdjust: "",
          salePriceNum: "",
          salePriceUnit: "",
        };
      }
      if (this.id == "") {
        this.pushGoodsObj.goodsIds = this.idArr;
      } else {
        this.pushGoodsObj.goodsIds = [this.id];
      }
      this.pushGoodsObj.isJd = this.params.isJd;

      let arr = ["group", "shop", "topic"];
      let arr1 = [];
      arr.forEach((item) => {
        if (this.pushGoodsObj.selectionObjects.includes(item)) {
          arr1.push(item);
        }
      });
      let { data } = await selectionGoodsApi(this.pushGoodsObj);
      this.$message({
        message: "推送成功",
        type: "success",
          });
      this.$refs.pushGoodsAll.close();
      this.$refs.pushGrounp.close();
    },
    async updatePrice(id, isJd) {
      this.$refs.goodInfoComponent.openGoodsInfo(id, isJd);
      this.$message({
        message: "更新成功",
        type: "success",
          });
    },
    async nextPush() {
      if (this.pushGoodsObj.selectionObjects.length == 0) {
        this.$message({
          message: "请选择推送目标",
          type: "warning",
          });
        return;
      }

      this.$refs.pushGrounp.open();
    },

    async pushAll() {
      this.id = "";
      this.state=""
      if (this.idArr.length == 0) {
        return;
      }
      this.$refs.pushGoodsAll.open();
      this.shopProps = [];
      this.topicProps = [];
      this.groupProps = [];
      let { data: res1 } = await getWxTopicByStaffIdApi();
      res1.forEach((item, index) => {
        this.topicProps.push({
          id: item.id,
          label: item.title,
        });
      });
      let { data: res2 } = await getWxShopByStaffIdApi();
      res2.forEach((item, index) => {
        this.shopProps.push({
          id: item.id,
          label: item.name,
        });
      });
      let { data: res3 } = await getGoodsGroupByStaffIdApi();
      res3.forEach((item, index) => {
        this.groupProps.push({
          id: item.id,
          label: item.name,
        });
      });
    },
    addIdArr(id) {
      if (this.idArr.includes(id)) {
        let index = this.idArr.indexOf(id);
        if (index !== -1) {
          this.idArr.splice(index, 1);
        }
      } else {
        this.idArr.push(id);
      }
      let a = true;
      this.rows.forEach((e) => {
        if (!this.idArr.includes(e.id)) {
          a = false;
        }
      });
      this.checkboxInput = a;
      this.$emit("setPoolIdArr",this.idArr)
      this.setPoolGoodsInfo();
    },
    pageChangeSize() {
      this.idArr = [];
    },
    checkboxInputEvent() {
      if (this.checkboxInput) {
        this.rows.forEach((e) => {
          if (!this.idArr.includes(e.id)) {
            this.idArr.push(e.id);
          }
        });
      } else {
        this.rows.forEach((e) => {
          let index = this.idArr.indexOf(e.id);
          if (index !== -1) {
            this.idArr.splice(index, 1);
          }
        });
      }
      this.$emit("setPoolIdArr",this.idArr)
      this.setPoolGoodsInfo();
    },
    //重置
    resetQuery() {
      this.params = {
        isJd: this.params.isJd,
        current: 1,
        size: 10,
        title: "",
        goodsGroupId: this.groupId,
        minSupplyPrice: "",
        maxSupplyPrice: "",
        maxRightRate: "",
        minRightRate: "",
        categoryId1: "",
        categoryId1List: [],
        categoryId2: "",
      };
      if (this.params.isJd === 2){
        this.$refs.goodsPlace2.click()
      } else {
        this.$refs.goodsPlace1.click()
      }
      this.getChooseGoodsList();
      this.idArr = [];
      this.$emit("setPoolIdArr",this.idArr)
      this.setPoolGoodsInfo();
    },
    async getChooseGoodsList() {
      this.checkboxInput = false;
      this.loading = true;
      if(this.userRole.includes('vipMember')){
        this.params.isVipGoods = true
      }else{
        this.params.isVipGoods = false
      }
      this.params.isShow = 1;
      this.params.isTwoShow = 1;
      let { data } = await unSelectedPoolGoodsApi(this.params);
      this.rows = data.rows;
      //this.totalCount = data.total;
      if (this.first) {
        this.getPageCount();
      }
      this.first = false;
      this.loading = false;
      let a = true;
      this.rows.forEach((e) => {
        if (!this.idArr.includes(e.id)) {
          a = false;
        }
      });
      this.checkboxInput = a;
    },
    async getPageCount() {
      if(this.userRole.includes('vipMember')){
        this.params.isVipGoods = true
      }else{
        this.params.isVipGoods = false
      }
      let { data } = await unSelectedPoolGoodsCountApi(this.params);
      this.totalCount = data;
    },
    pageChange(currentPage, pageSize) {
      this.first = true;
      this.params.current = currentPage;
      this.params.size = pageSize;
      this.getChooseGoodsList();
    },
    toggleDropdown(index) {
      this.$nextTick(() => {
        const dropdown = document.getElementById(`dropdown-a${index}`);
        new KTMenu(dropdown);
      });
    },
    changIsJd(index) {
      this.first = true;
      this.params.isJd = index;
      this.idArr = [];
      this.$emit("setPoolIdArr",this.idArr)
      this.$emit("setPoolIsJd",this.params)
      this.setPoolGoodsInfo();
      this.getChooseGoodsList();
    },
    openGoodInfo(id, isJd) {
      this.$refs.goodInfo.open();
      this.$refs.goodInfoComponent.openGoodsInfo(id, isJd);
    },
    shaixuan() {
      this.first = true;
      this.idArr = [];
      this.$emit("setPoolIdArr",this.idArr)
      this.setPoolGoodsInfo();
      this.getChooseGoodsList();
    },
    async findCategory(index) {
      this.first = true;
      if (index == 1) {
        let { data } = await getCategoryList({
          parentId: this.params.categoryId1,
        });
        this.params.categoryId2 = "";
        this.categoryTwo = data.list;
      }
      this.getChooseGoodsList();
    },
    //初始化分类
    async initCategory() {
      let { data } = await getCategoryList({
        parentId: 0,
      });
      this.categoryOne = data.list;
    },
  },
  created() {
    this.initCategory();
  },
  mounted() {},
  updated() {
    // 当数据更新后，可以在此处手动初始化下拉菜单插件
    this.rows.forEach((_, index) => {
      this.toggleDropdown(index);
    });
  },
};
</script>
<style>
</style>
