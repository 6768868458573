<template>
  <div style="min-height: 85vh">
    <TitleCard
      title="新建运费模板"
      style="margin: 10px 10px; max-width: 99%; border-radius: 15px"
    >
      <template v-slot:subTitle>
        <ul
          class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1"
          style="display: flex"
        >
          <li
            class="breadcrumb-item text-muted"
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
            style="font-family: 'Roboto', sans-serif"
          >
            Add Freight Template
          </li>

          <div
            class="align-items-center py-3"
            style="position: absolute; right: 0; padding: 0 30px"
          >
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-primary mx-2"
              @click="$router.replace('/goods/freight')"
              >返回到运费模板</a
            >
          </div>
        </ul>
      </template>
    </TitleCard>
    <el-form
      :model="freightInfo"
      label-width="150px"
      class="position demo-dynamic bg-white"
    >
      <el-form-item
          class="pt-7 position fw-bold"
          label="运费模板名称:"
          prop="templateName"
      >
        <el-input v-model.trim="freightInfo.title" class="w-300px"></el-input>
        <label class="ms-7 fs-6 fw-bold"> 模板状态: </label>
        <el-switch
            v-model="freightInfo.isStatus"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            active-text="启用"
            class="ms-5"
            inactive-color="#ff4949"
            inactive-text="禁用"
        >
        </el-switch>
        <label class="ms-7 fs-6 fw-bold"> 计价规则: </label>
        <el-radio v-model="freightInfo.isType" :label="1" class="ms-5">件数</el-radio>
        <el-radio v-model="freightInfo.isType" :label="2">重量</el-radio>
      </el-form-item>
      <div style="display: flex;justify-content: space-between;">
        <div>
          <label class="fw-bold" style="margin-left: 550px !important">
            收费地区
          </label>
          <el-form-item
              v-for="(item, index) in freightInfo.addressIdentifications"
              :key="index"
              :label="`地区及其收费规则${index + 1}:`"
              :rules="{ required: true }"
              class="pt-2 position fw-bold"
          >
            <el-input
                v-model="item.clothesAll"
                :readonly="true"
                :rules="{ required: true, message: '区域不能为空', trigger: 'blur' }"
                style="cursor: pointer"
                @focus="clickRechargeCreate(index, item.clothesId)"
            ></el-input>
            <div>
              <label style="margin-left: 6px !important"> 首件(件)/重(kg) </label>
              <label style="margin-left: 49px !important"> 首费(元) </label>
              <label style="margin-left: 37px !important"> 续件(件)/重(kg) </label>
              <label style="margin-left: 49px !important"> 续费(元) </label>
              <!--          <label style="margin-left: 59px !important"> 包邮(元)
                          <el-tooltip content="当商品购买金额>=当前值，则不收运费" effect="dark" placement="bottom"><i
                              class="el-icon-question"/>
                          </el-tooltip>
                        </label>-->
            </div>
            <el-input v-model="item.firstOne" class="w-100px me-5"></el-input>
            <el-input v-model="item.firstMoney" class="w-100px me-5"></el-input>
            <el-input v-model="item.second" class="w-100px me-5"></el-input>
            <el-input v-model="item.secondMoney" class="w-100px me-5"></el-input>
            <!--        <el-input class="w-100px me-5" v-model="item.satisfyMoney"></el-input>-->
            <el-button
                v-if="index !== 0"
                class="ms-5"
                style="margin-top: 10px"
                type="danger"
                @click="removeItem(index)"
            >删除
            </el-button
            >
          </el-form-item>

        </div>
        <img
            alt=""
            height="100%" src="https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com/saas%E8%BF%90%E8%B4%B9%E6%A8%A1%E6%9D%BF%E6%A1%88%E4%BE%8B.png" width="40%">
      </div>
      <div style="display: flex; justify-content: center">
        <div>
          <el-button class="ms-5 mb-5" type="primary" @click="addItem"
          >新增选项
          </el-button
          >
          <el-button class="ms-5 mb-5" type="primary" @click="submitFreight"
          >提交模板
          </el-button
          >
        </div>
      </div>

    </el-form>
    <ModalDialog ref="AddRegion" title="地区选择" width="35%" :closeBtn="true">
      <el-tree
        style="height: 500px !important; overflow-y: scroll"
        :data="treeData"
        show-checkbox
        accordion
        node-key="id"
        ref="tree"
      >
      </el-tree>
      <el-divider></el-divider>
      <!-- 只能让某一个子元素 居中 -->
      <div style="display: flex; justify-content: center">
        <div>
          <el-button class="me-auto ms-20" @click="clickRechargeClose(0)"
            >取消</el-button
          >
          <el-button
            class="me-auto ms-20"
            type="primary"
            @click="clickRechargeClose(1)"
            >确定</el-button
          >
        </div>
      </div>
    </ModalDialog>
  </div>
</template>
<script>
import {getJdRegionDetail} from "@/api/jdRegionApi";
import RechargeCreate from "@/views/Finance/components/RechargeRecord/RechargeCreate.vue";
import {addFreightTemplate} from "@/api/freightApi";

export default {
  components: { RechargeCreate },
  props: {},
  data() {
    return {
      index: 0,
      freightInfo: {
        title: "",
        isStatus: 2,
        isType: 1,
        addressIdentifications: [
          {
            provinceAll: "",
            provinceId: "",
            clothesAll: "",
            clothesId: "",
            firstOne: "",
            firstMoney: "",
            second: "",
            secondMoney: "",
            satisfyMoney: 2147483647,
          },
        ],
      },
      treeData: [
        {
          id: 1,
          label: "全部",
          children: [],
        },
      ],
    };
  },
  methods: {
    removeItem(index) {
      this.freightInfo.addressIdentifications.splice(index, 1);
    },
    addItem() {
      this.freightInfo.addressIdentifications.push({
        provinceAll: "",
        provinceId: "",
        clothesAll: "",
        clothesId: "",
        firstOne: "",
        firstMoney: "",
        second: "",
        secondMoney: "",
        satisfyMoney: "",
        money: "",
      });
    },
    // 树状数据 选择后 回显到页面上
    handleNodeClick(data) {
      data = this.$refs.tree.getCheckedNodes(false,true);
      let children = data;

      let ids=data.map(r=>{
        return r.id;
      })

      let str = [];
      let arrNum = [];
      let arr2 = [];
      let arr3 = [];
      children.forEach((item) => {
        if (item.label === "全部") {
          return;
        }
        if (!item.children) {
          return;
        }
        arr2.push(item.id);
        arr3.push(item.label);
        let arr = [];
        let arr1 = [];
        item.children.forEach((i) => {
          if (ids.includes(i.id)){
            arr.push(i.label);
            arr1.push(i.id);
          }
        });
        arrNum.push(arr1.join(","));
        str.push(`${item.label}(${arr.join(",")})`);
      });
      this.freightInfo.addressIdentifications[this.index].clothesId =
        arrNum.join("#") + "#";
      this.freightInfo.addressIdentifications[this.index].clothesAll =
        str.join("");
      this.freightInfo.addressIdentifications[this.index].provinceId =
        arr2.join(",");
      this.freightInfo.addressIdentifications[this.index].provinceAll =
        arr3.join(",");
    },
    // 获取上门安装模板信息
    async getFreightInfo(id) {
      let res = await id;
      this.freightInfo = res.data;
    },
    // 将地址数据回显到 弹窗的树状图
    async getJdRegion() {
      let res = await getJdRegionDetail();
      let obj = [
        {
          id: 1,
          label: "全部",
          children: [],
        },
      ];
      res.data.forEach((item) => {
        obj[0].children.push({
          id: item.provinceId,
          label: item.provinceName,
          children: [],
        });
        if (item.cityVo) {
          let a = obj[0].children.length - 1;
          item.cityVo.forEach((i) => {
            obj[0]["children"][a].children.push({
              id: i.cityId,
              label: i.cityName,
            });
          });
        }
        this.treeData = obj;
      });
    },
    // 弹窗
    clickRechargeCreate(index, ids) {
      if (ids) {
        ids = ids.replace(/#/g, ",").split(",");
        this.$refs.tree.setCheckedKeys(ids);
      } else {
        this.$refs.tree.setCheckedKeys([]);
      }
      this.index = index;
      this.$refs.AddRegion.open();
    },
    // 弹窗关闭 选择数据
    clickRechargeClose(type) {
      if (!type) {
        this.$refs.tree.setCheckedKeys([]);
        this.freightInfo.addressIdentifications.clothesAll = "";
      } else {
        this.handleNodeClick();
      }
      this.$refs.AddRegion.close();
    },
    // 提交模板
    async submitFreight() {
      if (!this.freightInfo.title) {
        this.$message({
          message: "请填写模板名称",
          type: "warning",
        });
        return;
      }

      if (!this.freightInfo.isType) {
        this.$message({
          message: "请选择计件规则",
          type: "warning",
        });
        return;
      }
      if (!this.freightInfo.isStatus) {
        this.$message({
          message: "请选择模板状态",
          type: "warning",
        });
        return;
      }


      const isAllEmpty = this.freightInfo.addressIdentifications.every(identification => {
        return Object.values(identification).some(value => value === '');
      });
      if (isAllEmpty) {
        this.$message({
          message: "请选择收费地区和收费规则",
          type: "warning",
        });
        return;
      }
      let res = await addFreightTemplate(this.freightInfo);
      this.$router.push("/goods/freight");
    },
  },
  created() {
    // 有id为编辑
    if (this.$route.params.id) {
      let id = this.$route.params.id;
      this.getFreightInfo(id);
    }
    this.getJdRegion();
  },
  mounted() {},
};
</script>
<style scoped>
.position {
  width: 85% !important;
  margin-left: 120px;
}
</style>
