<template>
  <div>


    <el-dialog
        title="详情"
        :visible.sync="vipDetailVisible"
        width="95%"
        top="6vh"
        @close="closeVipDetailDialog"
    >
      <div id="kt_app_content" class="app-content">
        <!--begin::Navbar-->
        <div class="card mb-6">
          <div class="card-body pb-0" style="padding-top: 0">
            <!--begin::Details-->
            <div class="d-flex flex-wrap " style="border: 1px solid #F1F1F2;padding: 16px;border-radius: 10px">
              <!--begin: Pic-->
              <div class="me-7 mb-4">
                <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                  <img :src="item.logo" alt="image"/>
                  <div
                      class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
                </div>
              </div>
              <!--end::Pic-->
              <!--begin::Info-->
              <div class="flex-grow-1 flex-wrap">
                <!--begin::Title-->
                <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                  <!--begin::User-->
                  <div class="d-flex flex-column">
                    <!--begin::Name-->
                    <div class="d-flex align-items-center mb-2">
                      <a href="#"
                         class="text-gray-900 text-hover-primary fs-2 fw-bold me-1">{{ item.name }}</a>
                      <a href="#">
                        <i class="ki-outline ki-medal-star fs-1 text-primary" style="font-size:3px">
                          {{ item.level }}</i>
                      </a>
                    </div>
                    <!--end::Name-->
                    <!--begin::Info-->
                    <div class="d-flex flex-wrap fw-semibold fs-6 pe-2" style="margin-top:5px">
                      <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                        <i class="ki-outline ki-profile-circle fs-4 me-1"></i>{{ item.userName }}</a>
                      <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                        <i class="ki-outline ki-phone fs-4 me-1"></i>{{ item.tel }}</a>
                      <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                        <i class="ki-outline ki-sms fs-4 me-1"></i>{{ item.email }}</a>
                    </div>
                    <div class="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2" style="margin-top:3px">
                      <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                        <i class="ki-outline ki-geolocation fs-4 me-1"></i>{{ item.address }}</a>
                    </div>
                    <div class="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2" style="margin-top:-1px">
                      <a href="javascript:void(0)"
                         class="align-items-center text-gray-400 text-hover-primary me-5 mb-2" style="max-width: 600px">
                        <i class="ki-outline ki-note fs-4 me-1"></i><span
                          class="fs-4">主营品牌：</span>{{ item.category }}</a>
                    </div>
                    <!--end::Info-->
                    <!--begin::Stats-->
                    <div class="d-flex flex-wrap flex-stack">
                      <!--begin::Wrapper-->
                      <div class="d-flex flex-column flex-grow-1 pe-8">
                        <!--begin::Stats-->
                        <div class="d-flex flex-wrap">
                          <!--begin::Stat-->
                          <div v-for="(item1,index) in item.goodsList.list"
                               class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <!--begin::Number-->
                            <div class="d-flex align-items-center">
                              <i class="ki-outline ki-verify fs-1 text-primary"></i>
                              <div class="fs-2 fw-bold" data-kt-countup="true" data-kt-countup-value="236">
                                {{ item1.count }}
                              </div>
                            </div>
                            <!--end::Number-->
                            <!--begin::Label-->
                            <div class=" flex-wrap fw-semibold fs-6 text-gray-400">{{ item1.title }}</div>
                            <!--end::Label-->
                          </div>
                          <!--end::Stat-->


                        </div>
                        <!--end::Stats-->
                      </div>
                      <!--end::Wrapper-->
                      <!--begin::Progress-->

                      <!--end::Progress-->
                    </div>
                    <!--end::Stats-->
                  </div>

                  <!--end::User-->
                  <!--begin::Actions-->
                  <div class="d-flex flex-wrap my-4">
                    <div class="d-flex align-items-center w-600px w-sm-400px flex-column mt-3">
                      <div class="d-flex justify-content-between w-100 mt-auto mb-2">
                        <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                          <i class="ki-outline ki-profile-circle fs-4 me-1"></i>公司介绍</a>
                      </div>
                      <div class="mx-3 w-100 bg-light mb-3">
                        {{ item.detail }}
                      </div>
                    </div>
                    <!--end::Menu-->
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Title-->

              </div>
              <!--end::Info-->
            </div>
            <div class="d-flex flex-wrap flex-stack">
              <!--begin::Title-->
              <h3 class="text-gray-800 fw-bold my-2">商品详情
                <!--<span class="fs-6 text-gray-400 fw-semibold ms-1">(29)</span>-->
              </h3>
              <!--end::Title-->
              <!--begin::Controls-->
            </div>
            <div class="card" style="border: 1px solid var(--bs-card-border-color);">
              <div class="card mb-5 mb-xl-8" style="margin-left: 3px; width: 100%;min-height: 80vh"
                   element-loading-text="拼命加载中"
                   element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"
                   v-loading="loading">
                <div class="card-body py-3">
                  <div class="table-responsive">
                    <table class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer"
                           id="kt_ecommerce_products_table">
                      <thead>
                      <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                        <th class="min-w-200px" tabindex="0" aria-controls="kt_ecommerce_products_table" rowspan="1"
                            aria-label="Product: activate to sort column ascending" colspan="1"
                            style="width: 212.141px">
                          商品信息
                        </th>
                        <th aria-label="分类" class="text-center" colspan="1" rowspan="1" style="width: 80px"
                            tabindex="0">
                          分类
                        </th>
                        <th aria-label="市场价" class="text-center" colspan="1" rowspan="1" style="width: 80px"
                            tabindex="0">
                          市场价
                        </th>
                        <th aria-label="分销价" class="text-center" colspan="1" rowspan="1" style="width: 80px"
                            tabindex="0">
                          分销价
                        </th>

                        <th class="text-center" tabindex="0" rowspan="1" colspan="1" aria-label="销售方式"
                            style="width: 80px">
                          销售方式
                        </th>
                        <th aria-label="库存" class="text-center" colspan="1" rowspan="1" style="width: 80px"
                            tabindex="0"
                        >
                          库存
                        </th>
                        <th class="text-center sorting_disabled" rowspan="1" colspan="1" aria-label="操作"
                            style="width: 80px">
                          操作
                        </th>
                      </tr>
                      </thead>
                      <tbody class="fw-semibold text-gray-600">
                      <tr v-for="(item, index) in rows" :key="index"
                          :class="{ old: index % 2 == 0, even: index % 2 != 0 }">
                        <!-- 商品信息 -->
                        <td>
                          <div class="d-flex align-items-center" @click="openGoodInfo(item.id,item.isJD)">
                            <a href="javascript:void(0);" class="symbol symbol-50px">
                              <img class="symbol-label lozad" v-if="item.picUrl.indexOf('http') == 0"
                                   :src="`${item.picUrl}`"/>
                              <img class="symbol-label lozad" v-else :src="`https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com${item.picUrl}`"/>
                            </a>
                            <div class="ms-5">
                              <a href="javascript:void(0);" class="text-gray-800 text-hover-primary fs-5"
                                 data-kt-ecommerce-product-filter="product_name">{{ item.title }}</a>
                            </div>
                          </div>
                        </td>
                        <td class="text-center pe-0">
                      <span v-html="item.categoryName" v-if="item.categoryName">
                      </span>
                          <span v-else v-html="[
                        item.categoryId1Name,
                        item.categoryId2Name,
                        item.categoryId3Name,
                      ].join('<br>')
                        ">
                      </span>
                        </td>
                        <!-- 市场价 -->
                        <td class="text-center pe-0">
                          <span>{{ item.price | format }} </span>
                        </td>
                        <!-- 分销价 -->
                        <td class="text-center pe-0">
                      <span>{{
                          item.supplPrice | format
                        }}</span>
                        </td>
                        <!-- 利润率 -->
                        <!-- 加价信息 -->
                        <!-- <td class="text-center pe-0">
                          <span class="fw-bold">{{ item.disPriceType }}</span>
                        </td> -->
                        <!-- 销售方式 -->
                        <td class="text-center pe-0">
                          <span>{{ item.startNum == 1 ? '一件代发' : `集采(${item.startNum}个起售)` }}</span>
                        </td>
                        <!-- 库存 -->
                        <td class="text-center pe-0">
                          <span>{{ item.stock }}</span>
                        </td>
<!--                        &lt;!&ndash; 操作 &ndash;&gt;-->
<!--                        <td class="text-center">-->
<!--                          <el-link type="primary" :underline="false" @click="openBuyOne(item)">购买</el-link>&nbsp;&nbsp;-->
<!--                          <el-link :underline="false" type="primary" @click="openBuyMany(item)">集采</el-link>-->
<!--                        </td>-->
                        <!-- 操作 -->
                        <td class="text-center">
                          <!--                    <el-link type="primary" :underline="false" @click="openBuyOneFromGoods(item)">购买</el-link>&nbsp;&nbsp;-->
                          <!--                    <el-link type="primary" :underline="false" @click="openBuyManyFromGoods">集采</el-link>-->
                          <a href="#" class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary"
                             data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" @click="toggleVipInfoDropdown(index)">
                            操作
                            <i class="ki-outline ki-down fs-5 ms-1"></i>
                          </a>

                          <div :id="'vipInfo-dropdown-' + index"
                               class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                               data-kt-menu="true">
                            <div class="menu-item px-3">
                              <a class="menu-link px-3" href="javascript:void(0)"
                                 @click="openBuyOne(item)">直接购买</a>
                            </div>
                            <div class="menu-item px-3">
                              <a href="javascript:void(0)" class="menu-link px-3" @click="
                            openBuyMany(item)
                            ">集采</a>
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="param.current"
                        :page-sizes="[10, 20, 30, 50]"
                        :page-size="100"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="vipDetailVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <buy-many :buyManyVisible="buyManyVisible" goods-staff-id=111 @closeDialog="closeBuyManyDialog"></buy-many>
    <el-dialog v-if="goodsDetailVisible" :visible.sync="goodsDetailVisible" appendToBody=true>
      <template #title>
        <div class="modal-content">
          <div class="modal-header">
            <h2>商品详情</h2>
          </div>
        </div>
      </template>
      <GoodInfoComponent ref="goodInfoComponent"></GoodInfoComponent>
    </el-dialog>


    <!-- 修改或新增收货地址 -->
    <el-dialog :close-on-click-modal="false" :title="status == 1 ? '新增收货信息' : '编辑收货信息'"
               :visible.sync="createOrEdit"
               width="30%">
      <el-form :model="editAddressObj">
        <el-form-item label="收货人" style="width:100%">
          <el-input v-model="editAddressObj.name" placeholder="请输入收货人"></el-input>
        </el-form-item>
        <el-form-item label="所在地区" style="margin-bottom:0">
        </el-form-item>
        <el-form :inline="true" label-position="top">
          <el-form-item label="省">
            <el-select v-model="editAddressObj.pro" style="width:120px" @change="proChange">
              <el-option v-for="item in proList" :key="item.id" :label="item.name" :value="item.kid"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="市">
            <el-select v-model="editAddressObj.city" style="width:120px" @change="cityChange">
              <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.kid"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="县(区)">
            <el-select v-model="editAddressObj.area" style="width:120px" @change="areaChange">
              <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.kid"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="街道">
            <el-select v-model="editAddressObj.stree" style="width:120px">
              <el-option v-for="item in streeList" :key="item.id" :label="item.name" :value="item.kid"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-form-item label="详细地址">
          <el-input v-model="editAddressObj.address" placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="editAddressObj.tel" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-checkbox v-model="editAddressObj.isType" false-label="2" true-label="1">设置默认地址</el-checkbox>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createOrEdit = false">取 消</el-button>
        <el-button type="primary" @click="saveAddress">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 收货地址列表 -->
    <el-dialog :visible.sync="addressListDialog" title="收货信息列表" width="50%">
      <el-table :data="addressList">
        <el-table-column label="收件人" property="name"></el-table-column>
        <el-table-column label="收件地址" property="proCity"></el-table-column>
        <el-table-column label="详细地址" property="address"></el-table-column>
        <el-table-column label="手机号" property="tel"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="selectAddress(scope.row)">选择</el-button>
            <el-button size="small" type="text" @click="editAddress(scope.row)">编辑</el-button>
            <el-button v-if="scope.row.isType == 2" size="small" type="text"
                       @click="setDefalt(scope.row)">设置默认
            </el-button>
            <el-button v-if="scope.row.isType == 2" size="small" type="text" @click="delAddress(scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addressListDialog = false">取 消</el-button>
        <el-button type="primary" @click="addressListDialog = false">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible" title="商品信息" width="60%">
      <el-descriptions :column="1" border class="margin-top" title="">
        <!-- <template slot="extra">
          <el-button type="primary" size="small">操作</el-button>
        </template> -->
        <el-descriptions-item>
          <template slot="label">
            标题
          </template>
          {{ itemTemp.title }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-mobile-phone"></i> -->
            市场价
          </template>
          {{ itemTemp.price | format }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-location-outline"></i> -->
            分销价
          </template>
          {{ itemTemp.supplPrice | format }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-tickets"></i> -->
            销售方式
          </template>
          {{ itemTemp.startNum == 1 ? '一件代发' : `集采(${itemTemp.startNum}个起售)` }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-office-building"></i> -->
            收件人信息
          </template>
          <el-descriptions :column="5" border direction="vertical" title="">
            <el-descriptions-item label="收件人">{{ addressObj.name }}</el-descriptions-item>
            <el-descriptions-item label="手机号">{{ addressObj.tel }}</el-descriptions-item>
            <el-descriptions-item label="收件地址">{{ addressObj.proCity }}{{
                addressObj.address
              }}
            </el-descriptions-item>
            <el-descriptions-item label="详细地址">{{ addressObj.address }}</el-descriptions-item>
            <el-descriptions-item label="操作">
              <el-link type="primary" @click="addAddress"><i
                  class="el-icon-plus el-icon--right"></i>新增
              </el-link>
              <el-link style="margin-left: 10px;" type="primary"
                       @click="moreAddress">更多
              </el-link>
            </el-descriptions-item>
          </el-descriptions>

        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-office-building"></i> -->
            购买数量
          </template>
          <el-input-number v-model="num" :min="1"></el-input-number>
        </el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="payGoods">购 买</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="procurementDialogFormVisible" title="集采" width="30%">
      <el-form ref="collectiveProcurementFromRuleForm" :model="collectiveProcurementFrom" :rules="rules">
        <el-form-item label="联系人" label-width="120px" prop="contacts">
          <el-input v-model="collectiveProcurementFrom.contacts" placeholder="请输入联系人" size="small"
                    style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="联系人手机号" label-width="120px" prop="tel">
          <el-input v-model="collectiveProcurementFrom.tel" placeholder="请输入手机号" size="small"
                    style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="数量" label-width="120px" prop="num">
          <el-input-number v-model="collectiveProcurementFrom.num" :min="1" size="small"></el-input-number>
        </el-form-item>
        <el-form-item label="配送方式" label-width="120px" prop="shippingMethod">
          <el-select v-model="collectiveProcurementFrom.shippingMethod" placeholder="请选择" size="small">
            <el-option :value="1" label="一次性发货"></el-option>
            <el-option :value="2" label="分批发货"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="配送区域" label-width="120px" prop="address">
          <el-input
              v-model="collectiveProcurementFrom.address"
              :rows="2"
              maxlength="100"
              placeholder="请输入地址"
              show-word-limit
              style="width: 60%;"
              type="textarea">
          </el-input>
        </el-form-item>
        <el-form-item label="单价" label-width="120px" prop="price">
          <el-input v-model="collectiveProcurementFrom.price" disabled placeholder="请输入期望单价" size="small"
                    @input="changeMessage($event)"
                    style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="备注" label-width="120px" prop="remark">
          <el-input
              v-model="collectiveProcurementFrom.remark"
              :rows="2"
              maxlength="100"
              placeholder="请输入备注"
              show-word-limit
              style="width: 60%;"
              type="textarea">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="procurementDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFrom">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>

import buyOne from "@/components/buyOne/index.vue";
import buyMany from "@/components/buyMany/index.vue";
import GoodInfoComponent from "@/components/GoodInfoComponent/index.vue";
import {getVipGoodsApi} from "@/api/vipAddressBookApi";
import {
  addAddressApi,
  buyPoolGoodsApi,
  defaultAddressApi,
  deleteAddressApi,
  getAddressInfoApi,
  getAddressPageApi,
  getJdRegionListApi,
  updateAddressApi
} from "@/api/chooseGoodsApi";
import {vipCollectiveProcurementSaveApi} from "@/api/VipCollectiveProcurementApi";

export default {
  components: {GoodInfoComponent, buyMany, buyOne},
  props: {
    vipDetailVisible: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
    },
    goodsList: {
      type: Array,
    }

  },
  data() {
    return {
      goodsItem: [],
      loading: false,
      buyManyVisible: false,
      buyOneVisible: false,
      goodsDetailVisible: false,
      createOrEdit: false,
      dialogVisible: false,
      addressListDialog: false,
      procurementDialogFormVisible: false,
      vipItem: {},
      itemTemp: "",
      collectiveProcurementFrom: {
        goodsId: "",
        contacts: "",
        tel: "",
        num: 1,
        shippingMethod: 1,
        remark: "",
        address: "",
        price: ""
      },
      rules: {
        contacts: [
          {required: true, message: '请输入联系人', trigger: 'blur'}
        ],
        tel: [
          {required: true, message: '请输入联系电话', trigger: 'blur'}
        ],
        num: [
          {required: true, message: '请输入数量', trigger: 'blur'}
        ],
        shippingMethod: [
          {required: true, message: '请选择配送方式', trigger: 'blur'}
        ],
        remark: [
          {required: false, message: '请输入备注', trigger: 'blur'}
        ],
        address: [
          {required: true, message: '请输入地址', trigger: 'blur'}
        ]
      },
      param: {
        current: 1,
        size: 10,
        staffId: ""
      },
      total: 0,
      rows: [],
      addressObj: {},
      num: "",
      editAddressObj: {
        name: "",
        tel: "",
        address: "",
        isType: 1,
        pro: 0,
        city: 0,
        area: 0,
        stree: 0
      },
      proList: [],
      cityList: [],
      areaList: [],
      streeList: []
    }
  },
  methods: {
    changeMessage() {
      this.$forceUpdate()
    },
    handleSizeChange(val) {
      this.param.size = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.param.current = val
      this.getList()
    },
    initList(item) {
      this.vipItem = item
      this.param.staffId = item.staffId
      this.getList()
    },
    async getList() {
      this.loading = true
      let {data} = await getVipGoodsApi(this.param)
      this.rows = data.rows
      this.total = data.total
      this.loading = false
    },
    openGoodInfo(id, isJd) {
      this.goodsDetailVisible = true
      //this.$refs.goodInfo.open()
      setTimeout(() => {
        this.$refs.goodInfoComponent.openGoodsInfo(id, isJd);
      }, 200)
    },
    openBuyMany(item) {
      this.collectiveProcurementFrom = {
        goodsId: "",
        contacts: "",
        tel: "",
        num: 1,
        shippingMethod: 1,
        remark: ""
      }
      console.log(item)
      this.collectiveProcurementFrom.goodsId = item.id
      this.collectiveProcurementFrom.price = item.supplPrice
      this.procurementDialogFormVisible = true
    },
    closeBuyManyDialog() {
      this.buyManyVisible = false
    },
    openBuyOne(item) {
      this.itemTemp = item;
      this.num = 1;
      this.getDefaultAddressPage()
    },
    async getDefaultAddressPage() {
      let {data} = await getAddressPageApi({
        name: "",
        tel: "",
        isType: 1,
        current: 1,
        size: 50
      })
      data.records.forEach((e) => {
        if (e.isType == 1) {
          this.addressObj = e;
        }
      })
      this.dialogVisible = true
    },
    async moreAddress() {
      this.addressListDialog = true;
      let {data} = await getAddressPageApi({
        name: "",
        tel: "",
        isType: "",
        current: 1,
        size: 50
      })
      this.addressList = data.records;
    },
    addAddress() {
      this.status = 1;
      console.log(1)
      this.proList = this.initJdRegionList(0)
      this.editAddressObj = {
        name: "",
        tel: "",
        address: "",
        isType: "2",
        pro: "",
        city: "",
        area: "",
        stree: ""
      }
      this.createOrEdit = true;
    },
    closeBuyOneDialog() {
      this.buyOneVisible = false
    },
    closeVipDetailDialog() {
      this.$emit("closeDialog")
    },
    async saveAddress() {
      let obj = {
        name: this.editAddressObj.name,
        pro: this.editAddressObj.pro,
        city: this.editAddressObj.city,
        area: this.editAddressObj.area,
        address: this.editAddressObj.address,
        tel: this.editAddressObj.tel,
        isType: this.editAddressObj.isType,
        stree: this.editAddressObj.stree
      }
      if (this.status == 2) {
        obj.id = this.editAddressObj.id
      }
      //修改
      if (this.status == 2) {
        let {data} = await updateAddressApi(JSON.stringify(obj))
      } else {
        //创建
        let {data} = await addAddressApi(obj)
      }
      this.moreAddress()
      this.getDefaultAddressPage()
      this.createOrEdit = false;
    },
    selectAddress(item) {
      this.addressObj = item
      this.addressListDialog = false
      this.$message({
        type: 'success',
        message: '选择成功!'
      });
    },
    async editAddress(item) {
      let {data} = await getAddressInfoApi({
        addressId: item.id
      })
      this.editAddressObj = data
      this.editAddressObj.pro = +data.pro
      this.editAddressObj.city = +data.city
      this.editAddressObj.area = +data.area
      this.editAddressObj.stree = +data.stree ? +data.stree : '';
      this.status = 2;
      this.proList = await this.initJdRegionList(0)
      this.cityList = await this.initJdRegionList(data.pro)
      this.areaList = await this.initJdRegionList(data.city)
      this.streeList = await this.initJdRegionList(data.area)
      this.createOrEdit = true;
    },
    async delAddress(item) {
      this.$confirm('此操作将永久删除该地址, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let {data} = await deleteAddressApi({
          addressId: item.id
        })
        let {data: res} = await getAddressPageApi({
          name: "",
          tel: "",
          isType: "",
          current: 1,
          size: 50
        })
        this.addressList = res.records;
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //购买商品
    payGoods() {
      this.$confirm('你确认购买当前商品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let obj = {
          goodsId: this.itemTemp.id,
          supplyPrice: this.itemTemp.supplPrice,
          goodsIsJd: this.itemTemp.isJD,
          addressId: this.addressObj.id,
          goodsQuantity: this.num,
          isVipGoods: true
        }
        let {data} = await buyPoolGoodsApi(obj)
        this.dialogVisible = false;
        this.$message({
          type: 'success',
          message: '购买成功!'
        });
      }).catch(() => {

      });
    },
    async setDefalt(item) {
      let obj = {
        addressId: item.id,
      }
      let {data} = await defaultAddressApi(obj)
      this.moreAddress()
      // this.getDefaultAddressPage()
      // this.addressListDialog = false;
    },
    submitFrom() {
      this.$refs["collectiveProcurementFromRuleForm"].validate(async (valid) => {
        if (!valid) {
          return false;
        }
        await vipCollectiveProcurementSaveApi(this.collectiveProcurementFrom);
        this.$message({
          message: "提交成功",
          type: "success",
        });
        this.procurementDialogFormVisible = false
      });
    },
    async initJdRegionList(id = 0) {
      //编辑时初始化省市区
      let {data} = await getJdRegionListApi({
        pId: id
      })
      return data;
    },
    toggleVipInfoDropdown(index) {
      this.$nextTick(() => {
        const dropdown = document.getElementById(`vipInfo-dropdown-${index}`);
        new KTMenu(dropdown);
      });
    },
  }
}

</script>

<style scoped>

</style>
